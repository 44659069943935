import React from "react";
import "dotenv/config";
import Loader from "../subviews/loader";
import { RoutePath, RoutingService } from "../service/routing-service";
import { getApiBaseUrl } from "../auth/config";

export default class ReviewInvoicesPage extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      loaded: false,
      error: false,
    };
  }

  async componentDidMount(): Promise<void> {}

  async submitFile(e: any) {
    e.preventDefault();

    this.setState({ loading: true });

    const formData = new FormData();
    const fileField = document.querySelector('input[type="file"]') as any;

    if (fileField) {
      formData.append("InvoiceFile", fileField.files[0]);

      const baseApi = getApiBaseUrl();

      try {
        await fetch(`${baseApi}/invoice/invoice-scans`, {
          method: "POST",
          body: formData,
          headers: { "X-CSRF": "1", mode: "no-cors" },
          credentials: "include",
        }).then((res) => {
          if (res.status !== 200) {
            this.setState({ loading: false, loaded: false, error: true });
            console.error("Error uploading file, check your network tab");
          } else {
            this.setState({ loading: false, loaded: true });
            RoutingService.instance.route(RoutePath.ReviewInvoices);
          }
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  render() {
    return (
      <div style={{ margin: "75px 5px 5px" }}>
        <h2>Manual import</h2>
        <div style={{ padding: "8px", borderBottom: "1px dashed lightgrey" }}>
          <form onSubmit={(e) => this.submitFile(e)}>
            {this.state.loading ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: "22px" }}>
                  <Loader />
                </div>
                &nbsp; Loading...
              </div>
            ) : (
              <>
                <p>Manual entry upload</p>
                <input
                  onChange={() => {
                    console.log("change");
                    this.setState({ loaded: true });
                  }}
                  type="file"
                  name="InvoiceFile"
                />
                <br />
                <br />
                <button
                  disabled={!this.state.loaded}
                  className="button"
                  type="submit"
                >
                  Import
                </button>
                {this.state.error && (
                  <p style={{ color: "red" }}>Error uploading file</p>
                )}
              </>
            )}
          </form>
        </div>
      </div>
    );
  }
}
