import React from "react";
import InvoiceDetails from "../subviews/invoice-details";
import "dotenv/config";
import Loader from "../subviews/loader";
import { getApiBaseUrl } from "../auth/config";

export default class ReviewInvoicesPage extends React.Component<any, any> {
  baseApi: string;
  constructor(props: any) {
    super(props);

    this.baseApi = getApiBaseUrl();

    this.state = {
      invoices: [],
      invoiceSelected: 0,
      loading: false,
      loaded: false,
      retrying: false,
    };
  }

  async fetchHistorical(): Promise<void> {
    this.setState({ retrying: false });

    const response = await fetch(
      `${this.baseApi}/invoice/invoice-scans/historical`,
      { headers: { "X-CSRF": "1", mode: "no-cors" }, credentials: "include" }
    );

    const invoices = await response.json();
    let invoicesSorted = [];

    if (invoices.length > 0) {
      invoicesSorted = invoices.reverse();
    }

    this.setState({ invoices: invoicesSorted, loaded: true });
  }

  async componentDidMount(): Promise<void> {
    this.fetchHistorical();

    setInterval(() => {
      this.fetchHistorical();
    }, 5000);
  }

  async submitFile(e: any) {
    e.preventDefault();

    this.setState({ loading: true });

    const formData = new FormData();
    const fileField = document.querySelector('input[type="file"]') as any;

    if (fileField) {
      formData.append("InvoiceFile", fileField.files[0]);

      await fetch(`${this.baseApi}/invoice/invoice-scans`, {
        method: "POST",
        body: formData,
        headers: { "X-CSRF": "1", mode: "no-cors" },
        credentials: "include",
      }).then(() => {
        this.setState({ loading: false });
      });
    }
  }

  handleRetry = async (id: number) => {
    await fetch(`${this.baseApi}/invoice/invoice-scans/${id}:retry`, {
      method: "POST",
      headers: { "X-CSRF": "1", mode: "no-cors" },
      credentials: "include",
    }).then(() => {});
  };

  render() {
    if (this.state.invoiceSelected > 0) {
      const invoiceContent = this.state.invoices.find((invoice: any) => {
        return invoice.id === this.state.invoiceSelected;
      });

      return (
        <InvoiceDetails
          goBack={() => {
            this.setState({ invoiceSelected: 0 });
          }}
          content={invoiceContent}
        />
      );
    }

    return (
      <div style={{ margin: "75px 5px 5px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h2>Review invoices</h2>
        </div>
        {this.state.invoices.length === 0 && !this.state.loaded && (
          <div style={{ display: "flex", width: "4%" }}>
            <Loader />
          </div>
        )}
        {this.state.invoices.length === 0 && this.state.loaded && (
          <div style={{ display: "flex", width: "100%" }}>
            No invoices found
          </div>
        )}
        {this.state.invoices.length > 0 &&
          this.state.invoices.map(
            ({ id, scanInvoiceStatus, invoiceScanResults }: any) => {
              // fetch scan status on page load
              fetch(`${this.baseApi}/invoice/invoice-scans/scan-status/${id}`, {
                headers: { "X-CSRF": "1", mode: "no-cors" },
                credentials: "include",
              });
              const receiver = invoiceScanResults.find(
                (item: any) => item.aliasKey === "RECEIVER_NAME"
              );

              const receiverName = receiver?.questionAnswers[0].answer || "-";

              // default to fail color
              let invoiceLineColor = "#ffe6d8";

              switch (scanInvoiceStatus) {
                case "Processing":
                  invoiceLineColor = "#54a3f1";
                  break;
                case "Success":
                  invoiceLineColor = "#036f15b8";
                  break;
                default:
                  invoiceLineColor = "#f36868";
              }

              return (
                <div
                  className="invoice-row"
                  key={id}
                  onClick={() => {
                    this.setState({ invoiceSelected: id });
                  }}
                >
                  <div
                    style={{
                      padding: "10px",
                    }}
                  >
                    #{id} {receiverName}
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                    }}
                  >
                    {scanInvoiceStatus === "Processing" && (
                      <div style={{ width: "22px" }}>
                        <Loader />
                      </div>
                    )}
                    {scanInvoiceStatus === "Failure" && !this.state.retrying && (
                      <button
                        style={{ background: "none", border: "none" }}
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          this.handleRetry(id);
                          this.setState({ retrying: true });
                        }}
                      >
                        <svg
                          width="18px"
                          height="18px"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill="#000000"
                            d="M14.9547098,7.98576084 L15.0711,7.99552 C15.6179,8.07328 15.9981,8.57957 15.9204,9.12636 C15.6826,10.7983 14.9218,12.3522 13.747,13.5654 C12.5721,14.7785 11.0435,15.5888 9.37999,15.8801 C7.7165,16.1714 6.00349,15.9288 4.48631,15.187 C3.77335,14.8385 3.12082,14.3881 2.5472,13.8537 L1.70711,14.6938 C1.07714,15.3238 3.55271368e-15,14.8776 3.55271368e-15,13.9867 L3.55271368e-15,9.99998 L3.98673,9.99998 C4.87763,9.99998 5.3238,11.0771 4.69383,11.7071 L3.9626,12.4383 C4.38006,12.8181 4.85153,13.1394 5.36475,13.3903 C6.50264,13.9466 7.78739,14.1285 9.03501,13.9101 C10.2826,13.6916 11.4291,13.0839 12.3102,12.174 C13.1914,11.2641 13.762,10.0988 13.9403,8.84476 C14.0181,8.29798 14.5244,7.91776 15.0711,7.99552 L14.9547098,7.98576084 Z M11.5137,0.812976 C12.2279,1.16215 12.8814,1.61349 13.4558,2.14905 L14.2929,1.31193 C14.9229,0.681961 16,1.12813 16,2.01904 L16,6.00001 L12.019,6.00001 C11.1281,6.00001 10.6819,4.92287 11.3119,4.29291 L12.0404,3.56441 C11.6222,3.18346 11.1497,2.86125 10.6353,2.60973 C9.49736,2.05342 8.21261,1.87146 6.96499,2.08994 C5.71737,2.30841 4.57089,2.91611 3.68976,3.82599 C2.80862,4.73586 2.23802,5.90125 2.05969,7.15524 C1.98193,7.70202 1.47564,8.08224 0.928858,8.00448 C0.382075,7.92672 0.00185585,7.42043 0.0796146,6.87364 C0.31739,5.20166 1.07818,3.64782 2.25303,2.43465 C3.42788,1.22148 4.95652,0.411217 6.62001,0.119916 C8.2835,-0.171384 9.99651,0.0712178 11.5137,0.812976 Z"
                          />
                        </svg>
                      </button>
                    )}
                    <b style={{ color: invoiceLineColor }}>
                      &nbsp; {scanInvoiceStatus}
                    </b>
                  </div>
                </div>
              );
            }
          )}
      </div>
    );
  }
}
