import React from "react";
import { AppBar } from "@material-ui/core";
import Swal from "sweetalert2";

import {
  Barcode,
  BarcodeResult,
  TextDataScannerResult,
} from "scanbot-web-sdk/@types";

import { NavigationContent } from "./subviews/navigation-content";
import { Toast } from "./subviews/toast";
import FeatureList from "./subviews/feature-list";
import { BottomBar } from "./subviews/bottom-bar";

import ImageResultsPage from "./pages/image-results-page";
import ImageDetailPage from "./pages/image-detail-page";

import Pages from "./model/pages";
import { ScanbotSdkService } from "./service/scanbot-sdk-service";
import { RoutePath, RoutingService } from "./service/routing-service";

import { ImageUtils } from "./utils/image-utils";
import { NavigationUtils } from "./utils/navigation-utils";
import { MiscUtils } from "./utils/misc-utils";
import DocumentScannerComponent from "./rtu-ui/document-scanner-component";
import { AnimationType } from "./rtu-ui/enum/animation-type";
import Barcodes from "./model/barcodes";
import ErrorLabel from "./subviews/error-label";
import { MrzResult } from "scanbot-web-sdk/@types/model/mrz/mrz-result";
import ResultParser from "./service/result-parser";
import ReviewInvoicesPage from "./pages/review-invoices-page";
import ManualImport from "./pages/manual-import-page";
import { postDocumentToScan } from "./api/common";

export default class App extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      alert: undefined,
      activeImage: undefined,
      sdk: undefined,
      error: {
        message: undefined,
      },
    };
  }

  async componentDidMount() {
    const sdk = await ScanbotSdkService.instance.initialize();
    this.setState({ sdk: sdk });

    RoutingService.instance.observeChanges(() => {
      this.forceUpdate();
    });

    await ScanbotSdkService.instance.setLicenseFailureHandler((error: any) => {
      RoutingService.instance.reset();

      this.setState({ error: { message: error } });
      if (this._documentScanner?.isVisible()) {
        this._documentScanner?.pop();
      }
    });
  }

  async submitDocument() {
    this.setState({ isSubmitting: true });
    const bytes = await ScanbotSdkService.instance.generatePDF(
      Pages.instance.get()
    );

    const invoicePDF = new Blob([bytes], { type: `application/pdf` });

    postDocumentToScan(invoicePDF).then((res) => {
      RoutingService.instance.route(RoutePath.ReviewInvoices);
      this.setState({ isSubmitting: false });
    });
  }

  onBackPress() {
    RoutingService.instance.back();
  }

  navigation?: any;

  toolbarHeight() {
    return (this.navigation as HTMLHeadingElement)?.clientHeight ?? 0;
  }

  containerHeight() {
    if (!this.navigation) {
      return "100%";
    }
    return window.innerHeight - 2 * this.toolbarHeight() ?? 0;
  }

  render() {
    return (
      <div>
        {this.documentScanner()}

        <Toast
          alert={this.state.alert}
          onClose={() => this.setState({ alert: undefined })}
        />

        <AppBar
          position="fixed"
          ref={(ref) => (this.navigation = ref)}
          style={{ zIndex: 19 }}
        >
          <NavigationContent
            backVisible={!NavigationUtils.isAtRoot()}
            onBackClick={() => this.onBackPress()}
          />
        </AppBar>
        <div
          style={{
            height: this.containerHeight(),
            marginTop: this.toolbarHeight(),
          }}
        >
          {this.decideContent()}
        </div>
        <BottomBar
          hidden={
            NavigationUtils.isAtRoot() || NavigationUtils.isAtReviewInvoices()
          }
          height={this.toolbarHeight()}
          buttons={this.decideButtons()}
        />
      </div>
    );
  }

  _documentScannerHtmlComponent: any;
  _documentScanner?: DocumentScannerComponent | null;
  documentScanner() {
    if (!this._documentScannerHtmlComponent) {
      this._documentScannerHtmlComponent = (
        <DocumentScannerComponent
          ref={(ref) => (this._documentScanner = ref)}
          sdk={this.state.sdk}
          onDocumentDetected={this.onDocumentDetected.bind(this)}
        />
      );
    }
    return this._documentScannerHtmlComponent;
  }

  decideContent() {
    const route = NavigationUtils.findRoute();

    if (NavigationUtils.isAtRoot() || route === RoutePath.DocumentScanner) {
      return (
        <div>
          <ErrorLabel message={this.state.error.message} />
          <FeatureList onItemClick={this.onFeatureClick.bind(this)} />
        </div>
      );
    }

    if (route === RoutePath.ReviewInvoices) {
      return <ReviewInvoicesPage />;
    }

    if (route === RoutePath.ManualImport) {
      return <ManualImport />;
    }

    if (route === RoutePath.ImageDetails) {
      if (!Pages.instance.hasActiveItem()) {
        RoutingService.instance.reset();
        return null;
      }
      return <ImageDetailPage image={this.state.activeImage} />;
    }
    if (route === RoutePath.ImageResults) {
      return (
        <ImageResultsPage
          sdk={this.state.sdk}
          onDetailButtonClick={async (index: number) => {
            Pages.instance.setActiveItem(index);
            this.setState({
              activeImage:
                await ScanbotSdkService.instance.documentImageAsBase64(index),
            });
            RoutingService.instance.route(RoutePath.ImageDetails, {
              index: index,
            });
          }}
        />
      );
    }
  }

  private decideButtons() {
    const route = NavigationUtils.findRoute();
    if (route === RoutePath.DocumentScanner) {
      return [
        { text: Pages.instance.count() + " PAGES", action: undefined },
        { text: "DONE", action: this.onBackPress.bind(this), right: true },
      ];
    }
    if (route === RoutePath.ImageResults) {
      if (this.state.isSubmitting) {
        return [{ text: "LOADING...", action: () => {} }];
      }
      return [{ text: "SUBMIT", action: this.submitDocument.bind(this) }];
    }
    if (route === RoutePath.ImageDetails) {
      return [
        { text: "DELETE", action: this.deletePage.bind(this), right: true },
      ];
    }
  }

  async detect() {
    await ScanbotSdkService.instance.croppingView?.detect();
  }

  async rotate() {
    await ScanbotSdkService.instance.croppingView?.rotate(1);
  }

  async applyCrop() {
    const result = await ScanbotSdkService.instance.croppingView?.apply();
    Pages.instance.updateActiveItem(result);
    await ScanbotSdkService.instance.reapplyFilter();
    this.onBackPress();
    const index = Pages.instance.getActiveIndex();
    this.setState({
      activeImage: await ScanbotSdkService.instance.documentImageAsBase64(
        index
      ),
    });
  }

  async savePDF() {
    const bytes = await ScanbotSdkService.instance.generatePDF(
      Pages.instance.get()
    );
    ImageUtils.saveBytes(bytes, MiscUtils.generateUUID() + ".pdf");
  }
  async saveTIFF() {
    const bytes = await ScanbotSdkService.instance.generateTIFF(
      Pages.instance.get()
    );
    ImageUtils.saveBytes(bytes, MiscUtils.generateUUID() + ".tiff");
  }

  async applyFilter() {
    const page = Pages.instance.getActiveItem();
    const result = await Swal.fire({
      title: "Select filter",
      input: "select",
      inputOptions: ScanbotSdkService.instance.availableFilters(),
      inputPlaceholder: page.filter ?? "none",
    });

    const filter = ScanbotSdkService.instance.filterNameByIndex(result.value);

    // "None" is not an actual filter, only used in this example app
    if (filter === "none") {
      page.filter = undefined;
      page.filtered = undefined;
    } else {
      page.filter = filter;
      page.filtered = await ScanbotSdkService.instance.applyFilter(
        page.cropped ?? page.original,
        filter
      );
    }

    const index = Pages.instance.getActiveIndex();
    this.setState({
      activeImage: await ScanbotSdkService.instance.documentImageAsBase64(
        index
      ),
    });
  }

  deletePage() {
    Pages.instance.removeActiveItem();
    RoutingService.instance.route(RoutePath.ImageResults);
  }

  async onDocumentDetected(result: any) {
    Pages.instance.add(result);
    ScanbotSdkService.instance.sdk?.utils.flash();

    console.log("Document detection result:", result);
  }

  async onBarcodesDetected(result: BarcodeResult) {
    Barcodes.instance.addAll(result.barcodes);
    // If you have any additional processing to do, consider pausing
    // the scanner here, else you might (will) receive multiple results:
    // ScanbotSdkService.instance.barcodeScanner?.pauseDetection();
    this.setState({
      alert: { color: "success", text: this.formatBarcodes(result.barcodes) },
    });
  }

  async onMrzDetected(mrz: MrzResult) {
    ScanbotSdkService.instance.mrzScanner?.pauseDetection();
    const text = ResultParser.MRZToString(mrz);
    await MiscUtils.alert(text);

    setTimeout(() => {
      ScanbotSdkService.instance.mrzScanner?.resumeDetection();
    }, 1000);
  }

  async onTextDataDetected(textData: TextDataScannerResult) {
    if (!textData) return;

    if (textData.validated) {
      ScanbotSdkService.instance.textDataScanner?.pauseDetection();
      await MiscUtils.alert(textData.text!);
      setTimeout(() => {
        ScanbotSdkService.instance.textDataScanner?.resumeDetection();
      }, 500);
    }
  }

  async onVINDetected(textData: TextDataScannerResult) {
    if (!textData) return;

    // The VIN scanner does not return empty results, so we can skip 'validated' check here
    // However, validated will still be true if several frames detected the same number
    ScanbotSdkService.instance.vinScanner?.pauseDetection();
    await MiscUtils.alert(textData.text!);
    setTimeout(() => {
      ScanbotSdkService.instance.vinScanner?.resumeDetection();
    }, 500);
    this.createOcrEngine({ mode: "VIN" });
    this.createOcrEngine();
  }

  async createOcrEngine(options?: { mode: string }) {}

  formatBarcodes(codes: Barcode[]): string {
    return JSON.stringify(
      codes.map((code: Barcode) => {
        return code.parsedText || `${code.text} (${code.format})`;
      })
    );
  }

  async onFeatureClick(feature: any) {
    const valid = await ScanbotSdkService.instance.isLicenseValid();
    if (!valid) {
      console.error(
        "License invalid or expired. ScanbotSDK features not available"
      );
      return;
    }

    if (feature.id === RoutePath.DocumentScanner) {
      this._documentScanner?.push(AnimationType.PushRight);
      return;
    }

    if (feature.route) {
      RoutingService.instance.route(feature.route);
      return;
    }

    if (feature.id === RoutePath.LicenseInfo) {
      const info = await this.state.sdk?.getLicenseInfo();
      const color = info?.status === "Trial" ? "success" : "error";
      this.setState({ alert: { color: color, text: JSON.stringify(info) } });
    }
  }
}
