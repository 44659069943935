import { RoutePath } from "../service/routing-service";

export class Features {
  public static LIST = [
    {
      id: RoutePath.DocumentScanner,
      name: "Document Scanner",
      route: RoutePath.DocumentScanner,
    },
    {
      id: RoutePath.ImageResults,
      name: "Image Results",
      route: RoutePath.ImageResults,
    },
    {
      id: RoutePath.ReviewInvoices,
      name: "Review Invoices",
      route: RoutePath.ReviewInvoices,
    },
    {
      id: RoutePath.ManualImport,
      name: "Import PDF",
      route: RoutePath.ManualImport,
    },
  ];
}
