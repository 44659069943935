import { getApiBaseUrl } from "../auth/config";

const baseApi = getApiBaseUrl();

export const getInvoiceHistorical = async (id: number) => {
  return fetch(`${baseApi}/invoice/historical/${id}`, {
    headers: { "X-CSRF": "1", mode: "no-cors" },
    credentials: "include",
  });
};

export const getAllInvoiceHistorical = async () => {
  await fetch(`${baseApi}/invoice/historical`, {
    headers: { "X-CSRF": "1", mode: "no-cors" },
    credentials: "include",
  }).then((response) => {
    return response.json();
  });
};

export const getInvoiceStatus = async (id: number) => {
  return fetch(`${baseApi}/invoice/scan-status/${id}`, {
    headers: { "X-CSRF": "1", mode: "no-cors" },
    credentials: "include",
  });
};

export const fetchHistory = async (id: number) => {
  return fetch(`${baseApi}/invoice/invoice-scans/historical/${id}`, {
    headers: { "X-CSRF": "1", mode: "no-cors" },
    credentials: "include",
  });
};

export const postDocumentToScan = async (invoiceFile: any) => {
  const data = new FormData();
  const baseApi = getApiBaseUrl();
  data.append("invoiceFile", invoiceFile);

  return fetch(`${baseApi}/invoice/invoice-scans`, {
    method: "POST",
    body: data,
    headers: { "X-CSRF": "1", mode: "no-cors" },
    credentials: "include",
  });
};
