import React, { ReactNode } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import {
  getApiBaseUrl,
  AuthActionTypeList,
  AuthWorkerResponseType,
} from "./auth/config";

import AuthWorker from "./auth/AuthWorker";
import worker from "./auth/auth-worker";

new AuthWorker(worker);

// Need to set up this and send over to the worker from here as workers don't have access to the window scope
const apiGatewayUrl = getApiBaseUrl();

// Here we're subscribing to the AUTH_CHANNEL channel to receive messages from the auth.worker
const channel = new BroadcastChannel("AUTH_CHANNEL");

// Timeout to make sure the worker is ready before sending the message
setTimeout(() => {
  channel.postMessage({
    action: AuthActionTypeList.LOGIN,
    apiGatewayUrl,
  });
}, 1000);

// This is our main message listener. It'll subscribe to some messages fired up from the worker and perform the appropriate actions
channel.onmessage = (ev: MessageEvent<AuthWorkerResponseType>) => {
  switch (ev.data.action) {
    case AuthActionTypeList.LOGGED_IN:
      renderApp(<App />);
      break;
    case AuthActionTypeList.NO_DATA:
      console.log("no data");
      attemptRedirect();
      break;
  }
};

const attemptRedirect = () => {
  try {
    window.location.href = `${apiGatewayUrl}/auth/login?returnUrl=${window.location.href}`;
  } catch (error) {
    renderApp(
      <React.StrictMode>
        <h1>Authentication failed</h1>
        <p>Error while redirecting to authenticate</p>
      </React.StrictMode>
    );
  }
};

const renderApp = (children: ReactNode) => {
  ReactDOM.render(
    <React.StrictMode>{children}</React.StrictMode>,
    document.getElementById("root")
  );
};
