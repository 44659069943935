import { AuthActionTypeList, UserData, getApiBaseUrl } from "./config";

type AuthActionTypeKey = keyof typeof AuthActionTypeList;
type AuthActions = typeof AuthActionTypeList[AuthActionTypeKey];
type AuthAction = {
  action: AuthActions;
  apiGatewayUrl?: string;
};

// Here we're subscribing to the AUTH_CHANNEL channel to receive messages from the main thread
const channel = new BroadcastChannel("AUTH_CHANNEL");

// Receiving messages from the main thread
channel.onmessage = async (ev: MessageEvent<AuthAction>) => {
  switch (ev.data.action) {
    case AuthActionTypeList.GET_USER: {
      const userData = await getUserData();
      // We should have the data at hand right now, so fire up a message with the data to all subscribers
      channel.postMessage({
        action: AuthActionTypeList.USER_DATA,
        userData,
      });
      break;
    }

    case AuthActionTypeList.LOGIN:
      await login();
      break;
    case AuthActionTypeList.LOGOUT:
      break;
  }
};

/**
 * Retrieves user data from the server.
 * @returns A promise that resolves to the user data or null if the request fails.
 */
export const getUserData = async (): Promise<UserData | null> => {
  const baseApi = getApiBaseUrl();
  const url = `${baseApi}/auth/user`;

  try {
    const response = await fetch(url, {
      headers: { "X-CSRF": "1", mode: "no-cors" },
      credentials: "include",
    });

    // Replaced the direct status code check with the `.ok` property of the response object. This property checks if the status is in the range 200-299, which is a more accurate way to check for a successful HTTP response.
    if (!response.ok) {
      return null;
    }

    const rawUserData = await response.json();

    if (!rawUserData || !rawUserData.length) {
      return null;
    }

    return formatUserData(rawUserData);
  } catch (error) {
    console.warn(error);
    return null;
  }
};

export const formatUserData = (rawUserData: any): UserData => {
  const formattedUserData: UserData = { roles: [] };

  rawUserData.forEach((item: any) => {
    if (item.type === "role") {
      formattedUserData?.roles?.push(item.value);
    } else {
      const type: keyof UserData = item.type;
      formattedUserData[type] = item.value;
    }
  });

  return formattedUserData;
};

/**
 * Performs a login operation using the provided API gateway URL.
 * Won't return any data directly, instead, will fire up subsequent messages to the subscribers with relevant data.
 * @param apiGatewayUrl The URL of the API gateway.
 */
export const login = async () => {
  const userData = await getUserData();

  console.log(`Logged as ${userData?.sub}`);

  if (userData) {
    channel.postMessage({
      action: AuthActionTypeList.LOGGED_IN,
      userData,
    });
  } else {
    channel.postMessage({
      action: AuthActionTypeList.NO_DATA,
    });
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { getUserData, formatUserData, login };
