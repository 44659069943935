export const AuthActionTypeList = {
  GET_USER: "get-user",
  LOGIN: "login",
  LOGGED_IN: "logged-in",
  LOGOUT: "logout",
  NO_DATA: "no-data",
  SET_USER: "set-user",
  USER_DATA: "user-data",
};

export type UserData = {
  amr?: string;
  sid?: string;
  sub?: string;
  auth_time?: string;
  idp?: string;
  name?: string;
  roles?: string[];
  company_displayid?: string;
};

type AuthActionTypeKey = keyof typeof AuthActionTypeList;
export type AuthActions = typeof AuthActionTypeList[AuthActionTypeKey];

export type AuthWorkerResponseType = {
  action: AuthActions;
  userData: UserData | null;
};

export const getApiBaseUrl = () => {
  const hostname = window.location.hostname;
  const splitHost = hostname.split(".");

  // Get environment (first part of the hostname)
  const environmentName = splitHost[0];

  // Get the workload from the environmentName (repairer-invoice => repairer)
  const applicationName = environmentName.split("-");
  const application = applicationName[1];
  const workload = applicationName[0];

  // Get domain
  const domain = splitHost.slice(1).join(".");

  if (domain === "partstrader.io") {
    return "https://devrepairerapi.partstrader.io/fss";
  }

  if (domain === "testpartstrader.us.com") {
    return `https://test${workload}api.testpartstrader.us.com/fss`;
  }

  if (domain === "partstrader.life") {
    const eodName = hostname.split("repairer");
    return `https://${eodName[0]}repairerapi.partstrader.life/fss`;
  }

  return `https://${workload}api.${domain}/${application}`;
};
